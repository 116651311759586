*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 24px;
  background-color: #dee2e6;
}
*::-webkit-scrollbar-track {
  background-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* controls */
#controls {
  width: 630px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "space-between";
}

.progress {
  height: 8px;
  width: calc(100% - 40px);
  background: #6f707c;
  margin: auto;
  border-radius: 6px;
  margin-left: 28px;
  position: relative;
  cursor: pointer;
}

.progress-filled {
  background: #5c7cfa;
  width: 0%;
  height: 100%;
  border-radius: 6px;
  transition: all 0.1s;
}

.progress-tip {
  position: absolute;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  top: -5px;
  background-color: white;
  cursor: pointer;
}

#btnPlayPause {
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-right: 10px;
}

.controls-modal {
  width: 368px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "space-between";
}

.progress-modal {
  height: 6px;
  width: 368px;
  background: #343a40;
  margin: auto;
  border-radius: 3px;
  margin-left: 28px;
}

.progress-modal-xs {
  height: 3px;
  width: 160px;
  background: #343a40;
  margin: auto;
  border-radius: 3px;
  margin-left: 28px;
}

.progress-filled-modal {
  background: #5c7cfa;
  width: 0%;
  height: 100%;
  border-radius: 3px;
  transition: all 0.1s;
}

.progress-tip-modal {
  position: relative;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  top: -11px;
  background-color: #5c7cfa;
}

#btn-play-pause-modal {
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-right: 10px;
}

#timer {
  color: white;
  font-size: 14;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

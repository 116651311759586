body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slide {
  border: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#progressLine {
  transition: width 0.7s;
}
div.infinite-scroll-component__outerdiv {
  width: 100%;
}

#time-picker::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

#root_body {
  overflow-x: hidden;
}

@font-face {
  font-family: "MontserratExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Regular.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Roboto-Medium.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "RobotoBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Bold.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "KCC";
  src: local("KCC"), url("./assets/fonts/KCC.otf") format("opentype");
  font-weight: normal;
}

/* SpoqaHansSansNeo */
@font-face {
  font-family: "SpoqaHansSans-Bold";
  src: local("SpoqaHanSansNeo-Bold"),
    url("./assets/fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./assets/fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpoqaHansSans-Medium";
  src: local("SpoqaHanSansNeo-Medium"),
    url("./assets/fonts/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("./assets/fonts/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("./assets/fonts/SpoqaHanSansNeo-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpoqaHansSans-Light";
  src: local("SpoqaHanSansNeo-Light"),
    url("./assets/fonts/SpoqaHanSansNeo-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpoqaHansSans-Regular";
  src: local("SpoqaHanSansNeo-Regular"),
    url("./assets/fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpoqaHansSans-Thin";
  src: local("SpoqaHanSansNeo-Thin"),
    url("./assets/fonts/SpoqaHanSansNeo-Thin.ttf") format("truetype");
  font-weight: normal;
}
